<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="closeDialog"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="true"
                :formItems="popForms"/>
            </a-modal>
         <a-modal 
            title="审批" 
            :confirm-loading="confirmLoading" 
            @ok="check"
            v-model:visible="showCheckDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="false"
                :formItems="checkForms"/>
            </a-modal>
        <m-form
            :formItems="formItems"
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @modifyStatus="modifyStatus($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { isValid, agentApplyList, agentApplyDetail, agentCheck } from '../../utils/api'
import { assginDataToArray, formatTime, geneIndex } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '申请详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'},
                 {text: '审批', eventName: 'modifyStatus'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '真实姓名', dataIndex: 'realName', key: 'realName'},
                 {title: '手机号码', dataIndex: 'phone', key: 'phone'},
                 {title: '昵称', dataIndex: 'nickName', key: 'nickName'},
                 {title: '申请时间', dataIndex: 'createTime', key: 'createTime'},
                //  {title: '收货人电话', dataIndex: 'consigneePhone', key: 'consigneePhone'},
                 {title: '单据状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                //  {type: 'input', label: '配送单ID', value: undefined},
                //  {type: 'selector', key: 'value', options: [{label: '待领取', value: 1}, {label: '已领取', value: 1}, {label: '配送中', value: 5}, {label: '已完成', value: 10}, {label: '已取消', value: 99}], label: '状态', value: undefined},
                //  {type: 'button', eventName: 'loadData', label: '搜索'},
                //  {type: 'button', eventName: 'refresh', label: '手动生成订单'}
             ],
             checkForms: [
                 {label: '代理状态', type: 'selector', value: '', key: 'value', prop: 'status', options: [{labelName: '待处理', value: 0}, {labelName: '通过', value: 1}, {labelName: '驳回', value: 2}]},
                 {label: '留言', type: 'input', value: '', prop: 'remark'}
             ],
             popForms: [
                 {label: 'ID', prop: 'id', value: '', type: 'input'},
                 {label: '真实姓名', prop: 'realName', value: '', type: 'input'},
                 {label: '状态', prop: 'statusText', value: '', type: 'input'},
                //  {label: '配送员ID', prop: 'operatorId', value: '', type: 'input'},
                //  {label: '配送员', prop: 'operatorName', value: '', type: 'input'},
                 {label: '手机号', prop: 'phone', value: '', type: 'input'},
                 {label: '昵称', prop: 'nickName', value: '', type: 'input'},
                 {label: '申请时间', prop: 'createTime', value: '', type: 'input'},
                 {label: '修改时间', prop: 'updateTime', value: '', type: 'input'},
                //  {label: '收货人电话', prop: 'consigneePhone', value: '', type: 'input'},
                 {label: '备注', prop: 'remark', value: '', type: 'input'},
                //  {label: '创建时间', prop: 'createTime', value: '', type: 'input'},
            ],
            modifyItems: [
                {label: '单据状态', type: 'selector', value: '', options: [], key: 'name',},
                {label: '备注', type: 'input', value: ''}
                // {label: '是否生成票配送单', type: 'selector', value: '', key: 'value', options: [{label: '是', value: 1}, {label: '否', value: 0}], button: {eventName: 'changeTicketDeliverStatus', label: '修改'}}
            ],
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             selectedIDs: [],
             loading: false,
             status: 0,
             detail: {},
             showCheckDialog: false,
        })
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    id: '',
                    status: state.status,
                }
                const result = await agentApplyList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data, state.pageSize, state.currentPage)
                    state.tableList.forEach(ele => {
                        const s = ele.status
                        ele.createTime = formatTime(ele.createTime)
                        ele.statusText = s == 0 ? '待处理' 
                                        :s == 1 ? '已通过' : '已驳回'
                    })
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        function modifyStatus(event) {
            state.detail = event
            state.checkForms.forEach(ele => {
                ele.value = event[ele.prop]
            })
            state.showCheckDialog = true
        }
        async function detail(event) {
            try {
                const result = await agentApplyDetail(event.id)
                if (isValid(result)) {
                    const s = result.data.status
                        result.data.statusText = s == 0 ? '待处理' 
                                        :s == 1 ? '已通过' : '已驳回'
                    result.data.createTime = formatTime(result.data.createTime)
                    result.data.updateTime = formatTime(result.data.updateTime)    
                    state.popForms = assginDataToArray(result.data, state.popForms)
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }

        async function check() {
            try {
                const id = state.detail.id 
                const items = state.checkForms
                const result = await agentCheck({
                    id: id,
                    status: items[0].value,
                    remark: items[1].value
                })
                if (isValid(result)) {
                    message.success('修改成功')
                    loadData()
                    state.showCheckDialog = false
                }
            } catch(e) {
                console.error(e)
            }
        }
       
        function closeDialog() {
            loadData()
            state.showDialog = false
        }
        
        return {
            ...toRefs(state),
            detail,
            loadData,
            modifyStatus,
            closeDialog,
            check
        }
    },
}
</script>
<style lang='scss' scoped>
</style>